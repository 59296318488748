import { useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { Auth } from "aws-amplify";
import { CustomInput } from "../../../component";
import { VerifyUser } from "../.";
import { withConsentData } from "../../../hoc";
import { generateRandomString } from "../../../utils";

/**
 * @author: nadeem@passbird.co
 * Sign in component
 * @returns React Component
 */

export const SignIn = withConsentData(({ msg, consentToken, fromEmail }) => {
  const [openModal, toggleModal] = useState(false);
  const [user, setUser] = useState(null);
  const [inProgress, setInProgress] = useState();
  const navigate = useNavigate();

  const navigateToConsent = useCallback(() => {
    navigate(`/consent/?consentToken=${consentToken}`);
  }, [consentToken, navigate]);

  const getAuthState = useCallback(async () => {
    try {
      const currentSession = await Auth.currentSession();
      if (currentSession && consentToken) {
        const currentEmail = currentSession.getIdToken().payload["email"];
        if (currentEmail && currentEmail !== fromEmail) {
          await Auth.signOut();
          throw Error("Incorrect Session");
        }
        navigateToConsent();
      }
    } catch (err) {
      console.log(err);
    }
  }, [consentToken, fromEmail, navigateToConsent]);

  useEffect(() => {
    getAuthState();
  }, [getAuthState]);

  const signupUser = async () => {
    try {
      await Auth.signUp({
        username: fromEmail,
        password: generateRandomString(),
      });
      await onVerify();
    } catch (err) {
      console.log(err);
    }
  };

  const onVerify = async () => {
    setInProgress(true);
    try {
      const cogUser = await Auth.signIn(fromEmail);
      setUser(cogUser);
      toggleModal(true);
    } catch (err) {
      if (err.message && err.message.includes("User not found")) signupUser();
    } finally {
      setInProgress(false);
    }
  };

  const onSubmit = async (code) => {
    const resp = await Auth.sendCustomChallengeAnswer(user, code);
    if (resp.challengeName) throw new Error("Invalid code");
    navigateToConsent();
  };

  if (openModal) return <VerifyUser email={fromEmail} onSubmit={onSubmit} />;

  return (
    <>
      <div className="form-body">
        <div className="form-heading">
          <h1>
            MetaKeep <br />
            Sign In
          </h1>
        </div>
        <CustomInput
          id="email"
          label="Email"
          disabled={true}
          value={fromEmail}
        />
        {msg.content && (
          <span className={`msg ${msg.type}`}>{msg.content}</span>
        )}
        <div className="form-cta">
          <Button
            disabled={!!msg.content || inProgress}
            onClick={onVerify}
            className="primary-btn"
          >
            {inProgress ? "Please Wait" : "Verify Email"}
          </Button>
        </div>
      </div>
    </>
  );
});
