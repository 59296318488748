/* eslint-disable no-useless-escape */
export const emailRegx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i;
/**
 * @author: nadeem@passbird.co
 * @param {string} fieldType  field type to be validated
 * @param {string} value  value for the field
 * @returns error object
 */

export const validator = (fieldType, value) => {
  const errors = {};
  switch (fieldType) {
    case "email":
      errors[fieldType] = "Please enter a valid Email Address";
      if (value && value.match(emailRegx)) errors[fieldType] = null;
      break;
    case "code":
      errors[fieldType] = "Please enter a valid 6 digit Code";
      if (value && value.length === 6) errors[fieldType] = null;
      break;
    default:
      return;
  }
  return errors;
};
