import { useCallback, useState } from "react";
import { validator } from "../utils";

/**
 * @author: nadeem@passbird.co
 * @param {object} formConfig fields to be verified by validator
 * validates inputs, also provides for input onchange handlers
 * @returns Object
 */
export const useForm = (formConfig) => {
  const [values, setValues] = useState(() => {
    return { ...formConfig };
  });
  const [errors, setErrors] = useState(() => {
    return { ...formConfig };
  });

  /**
   * Validates user input
   * @param {string} name key value of the error object
   * @param {string} value value to be validated
   * @returns boolean
   */
  const validateFields = (name, value) => {
    const errorsOb = validator(name, value);
    setErrors((prevState) => {
      return {
        ...prevState,
        ...errorsOb,
      };
    });
    return Object.values(errorsOb).filter(Boolean).length === 0;
  };

  /**
   * handler to map user inputs with input id
   * @param {object} evt dom event object
   */
  const onChangeHandler = (evt) => {
    const { id, value } = evt.target;
    validateFields(id, value);
    setValues((prevState) => {
      return {
        ...prevState,
        [id]: value,
      };
    });
  };

  /**
   * function used to validate before CTAs
   * @returns Boolean
   */
  const checkFormValidity = useCallback(() => {
    const formKeyValues = Object.keys(values);
    const isValid = formKeyValues.map((elm) => {
      return validateFields(elm, values[elm]);
    });

    return isValid.filter(Boolean).length === formKeyValues.length;
  }, [values]);

  const setDirectValue = useCallback((key, value) => {
    setValues((prevState) => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  }, []);

  return {
    onChangeHandler,
    errors,
    values,
    checkFormValidity,
    setDirectValue,
  };
};
