import { API, Auth } from "aws-amplify";
import { CONSENT_BACKEND_URL } from "../constants";

export const API_NAME = "ConsentApi";

API.configure({
  endpoints: [{ name: API_NAME, endpoint: CONSENT_BACKEND_URL }],
});

const consentDataCacheMap = new Map();

export const cachedConsentData = async (consentToken) => {
  const cachedData = consentDataCacheMap.get(consentToken);
  if (cachedData) {
    return cachedData;
  }
  const consentData = await getConsentData(consentToken);
  consentDataCacheMap.set(consentToken, consentData);
  return consentData;
};

export const getConsentData = async (consentToken) => {
  return API.get(API_NAME, `/getConsentTokenData?consentToken=${consentToken}`);
};

export const grantConsent = async (consentToken) => {
  const apiConfig = {
    body: {
      consentToken,
    },
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
  };
  return API.post(API_NAME, `/grantConsent`, apiConfig);
};
