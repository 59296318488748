const NFT_TYPE_KEY = "transferNftRequest";
const COIN_TYPE_KEY = "transferCoinRequest";
export * from "./validator";

export const sanitizeStatusString = (str) =>
  typeof str === "string" ? str.replace(/_/g, " ") : "Error Occured";

export const generateRandomString = (length = 8) =>
  Math.random().toString(20).substr(2, length);

export const postMessage = (eventType, message, callback) => {
  if (!eventType) return;
  window.parent.postMessage({ eventType: eventType, payload: message }, "*");
  if (callback && typeof callback === "function") {
    callback();
  }
};

export const parseConsentData = (consentData) => {
  const { developerName, tokenMetadata = {} } = consentData;
  const isNFT = consentData?.hasOwnProperty(NFT_TYPE_KEY);
  const consetDetail =
    (isNFT ? consentData[NFT_TYPE_KEY] : consentData[COIN_TYPE_KEY]) || {};
  const obj = {
    fromEmail: consetDetail?.from?.email || null,
    toEmail: consetDetail?.to?.email || null,
    developerName,
    isNFT,
  };
  if (isNFT) {
    obj.nftCollection = consetDetail?.nft?.collection || null;
    obj.nftTokenId = consetDetail?.token || null;
    obj.tokenMetadata = tokenMetadata;
  } else {
    obj.coin = consetDetail?.coin?.symbol || null;
    obj.amount = consetDetail?.amount || null;
  }
  return obj;
};
