import { Button } from "reactstrap";

export const CustomModal = ({ copy, onConfirm, onCancel }) => {
  return (
    <div className="custom-overlay">
      <div className="custom-modal">
        <div className="custom-modal-copy">{copy}</div>
        <div className="custom-modal-cta">
          <Button
            onClick={onConfirm}
            className="primary-btn clr-transp lg round"
          >
            Yes
          </Button>
          <Button onClick={onCancel} className="primary-btn lg round">
            No
          </Button>
        </div>
      </div>
    </div>
  );
};
