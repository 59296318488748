import { useCallback, useMemo, useState } from "react";
import { Auth } from "aws-amplify";
import clx from "classnames";
import { Button } from "reactstrap";
import { postMessage, sanitizeStatusString } from "../../utils";
import { grantConsent } from "../../api";
import { withConsentData } from "../../hoc";
import {
  CompletedState,
  CustomModal,
  SecuredByFooter,
  NftItem,
  CloseFrame,
} from "../../component";
import { VerifyUser } from "../.";
import { CONSENT_MESSAGE_MAP } from "../../constants";

/**
 * @author: nadeem@passbird.co
 * Sign in component
 * @returns React Component
 */

export const ConsentFrame = withConsentData(
  ({
    fromEmail,
    developerName,
    coin,
    amount,
    msg,
    consentToken,
    tokenMetadata = {},
    isNFT,
  }) => {
    const [trxStatus, setTrxStatus] = useState(null);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showAuthCodeModal, setShowAuthCodeModal] = useState(false);

    // check if user has valid session
    const hasSession = useCallback(async () => {
      try {
        const currentSession = await Auth.currentSession();
        const currentEmail = currentSession.getIdToken().payload["email"];
        if (currentEmail && currentEmail !== fromEmail) {
          await Auth.signOut();
          throw Error("Invalid Session");
        }
        return true;
      } catch (err) {
        return false;
      }
    }, [fromEmail]);

    //Sign out of the app
    const onDenyConsent = useCallback(async () => {
      setShowConfirmModal(false);
      setShowAuthCodeModal(false);
      setTrxStatus("Denied");
      setTimeout(() => {
        postMessage("Error", { status: "USER_CONSENT_DENIED" });
      }, 1000);
    }, []);

    const onConfirm = useCallback(async () => {
      setTrxStatus("Please Wait");
      const validSession = await hasSession();
      if (!validSession) {
        setShowAuthCodeModal(true);
        return;
      }
      try {
        setShowAuthCodeModal(false);
        const response = await grantConsent(consentToken);
        const { status, transactionId } = response;
        setTrxStatus(transactionId ? "Given" : sanitizeStatusString(status));
        // post message to parent window
        setTimeout(() => {
          postMessage("Success", response);
        }, 1000);
      } catch (err) {
        const errData = err?.response?.data || err;
        postMessage(
          "Error",
          errData,
          setTrxStatus("Failed to complete transactions")
        );
      }
    }, [consentToken, hasSession]);
    const children = useMemo(() => {
      if (trxStatus) {
        if (trxStatus === "Given" || trxStatus === "Denied") {
          return (
            <CompletedState
              msg={CONSENT_MESSAGE_MAP[trxStatus]}
              isSuccess={trxStatus === "Given"}
            />
          );
        }
        return <ProgressState msg={trxStatus} />;
      }

      return (
        <>
          <div className="form-heading">
            <div className="form-detail">
              <h2>{developerName}</h2>
            </div>
          </div>
          {msg.content ? (
            <div className="form-body">{msg.content}</div>
          ) : (
            <div className="form-body">
              <div className={clx("light-text", "description-text")}>
                Requests your permission to
              </div>
              <TransferItem
                isNFT={isNFT}
                tokenMetadata={tokenMetadata}
                amount={amount}
                coin={coin}
              />
            </div>
          )}
          {!msg?.content && (
            <div className="form-cta mt-4">
              <Button
                disabled={!!msg.content}
                onClick={onConfirm}
                className="primary-btn round lg"
              >
                Confirm
              </Button>
            </div>
          )}
        </>
      );
    }, [
      trxStatus,
      developerName,
      msg.content,
      isNFT,
      tokenMetadata,
      amount,
      coin,
      onConfirm,
    ]);

    const confirmationModal = useMemo(() => {
      if (showConfirmModal)
        return (
          <CustomModal
            copy="Are you sure that you want to cancel this transfer?"
            onConfirm={onDenyConsent}
            onCancel={() => setShowConfirmModal(false)}
          />
        );
    }, [onDenyConsent, showConfirmModal]);

    return (
      <>
        <CloseFrame onClose={() => setShowConfirmModal(true)} />
        {confirmationModal}
        {showAuthCodeModal ? (
          <VerifyUser
            email={fromEmail}
            developerName={developerName}
            callback={onConfirm}
          />
        ) : (
          children
        )}
        <SecuredByFooter />
      </>
    );
  }
);

const TransferItem = ({
  amount = "",
  coin = "",
  isNFT = false,
  tokenMetadata,
}) => {
  const statement = !isNFT && `${amount} ${coin}`;
  return (
    <div className="consent-for-desc">
      <div className="consent-desc">Transfer {isNFT ? "your NFT" : ""}</div>
      {isNFT ? (
        <NftItem metadata={tokenMetadata} />
      ) : (
        <div className="asset-desc">{statement}</div>
      )}
    </div>
  );
};

const ProgressState = ({ msg }) => {
  return <div className="empty-state in-frame">{msg}</div>;
};
