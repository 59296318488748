//! AWS REGION
export const AWS_REGION = process.env.REACT_APP_AWS_REGION;
//! AWS COGNITO POOL ID
export const POOL_ID = process.env.REACT_APP_AWS_COGNITO_POOL_ID;
//! AWS COGNITO CLIENT ID
export const COGNITO_CLIENT_ID = process.env.REACT_APP_AWS_COGNITO_CLIENT_ID;
// CONSENT BACKEND URL
export const CONSENT_BACKEND_URL = process.env.REACT_APP_CONSENT_BACKEND_URL;

export const CONSENT_MESSAGE_MAP = {
  Given: "You can close this window.",
  Denied: "Transfer is cancelled. You may close this window now.",
};
