export const CloseFrame = ({ onClose }) => {
  return (
    <div className="close-frame">
      <img
        data-testid="close-frame"
        onClick={onClose}
        src="/close.svg"
        alt=""
      />
    </div>
  );
};
