import { useCallback, useEffect, useState } from "react";
import { Auth } from "aws-amplify";

import { useForm } from "../../../hooks";
import { OTPField } from "../../../component/OTPField";
import { LoaderWithBackdrop } from "../../../component/Loader";
import { generateRandomString } from "../../../utils";
/**
 * @author nadeem@passbird.co
 * @param {function} callback - runs after code input
 * @param {string} email email address
 * @param {string} developerName - consent initiator
 * @returns React component
 */

export const VerifyUser = ({ callback, email, developerName = "DEV_NAME" }) => {
  const { values, checkFormValidity, setDirectValue } = useForm({
    code: null,
  });
  const [authErr, setAuthErr] = useState(null);
  const [inProgress, setProgress] = useState(false);
  const [user, setUser] = useState(null);
  const { code } = values;

  const signinUser = useCallback(async () => {
    setProgress(true);
    try {
      const cogUser = await Auth.signIn(email);
      setUser(cogUser);
    } catch (err) {
      if (err.message && err.message.includes("User not found")) {
        const { userConfirmed } = await Auth.signUp({
          username: email,
          password: generateRandomString(),
        });
        if (userConfirmed) await signinUser();
      }
    } finally {
      setProgress(false);
    }
  }, [email]);

  useEffect(() => {
    if (email) signinUser();
  }, [email, signinUser]);

  const onVerify = useCallback(async () => {
    const isValid = checkFormValidity();
    if (!isValid) return;
    setProgress(true);
    try {
      const resp = await Auth.sendCustomChallengeAnswer(user, code);
      if (resp.challengeName) throw new Error("Invalid code");
      if (callback && typeof callback === "function") callback();
    } catch (err) {
      setProgress(false);
      setAuthErr("Invalid code");
    }
  }, [callback, checkFormValidity, code, user]);

  useEffect(() => {
    if (code && code.length === 6) onVerify();
  }, [code, onVerify]);

  const handleCodeChange = useCallback(
    (val) => {
      setAuthErr(null);
      setDirectValue("code", val);
    },
    [setDirectValue]
  );

  return (
    <div className="auth-modal">
      <div className="auth-modal-title">{developerName}</div>
      <div className="content">
        <div className="auth-copy">
          For extra security, we sent a confirmation code to <br />{" "}
          <b>{email}</b>
          <div className="sub">please enter it below</div>
        </div>
        {authErr && <div className="auth-err">{authErr}</div>}
        <OTPField onChangeHandler={handleCodeChange} hasError={authErr} />
      </div>
      {inProgress && <LoaderWithBackdrop />}
    </div>
  );
};
