import { useRef, useState } from "react";
import clx from "classnames";
export const NftItem = ({ metadata = {} }) => {
  const imgRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { image = null, background_color = "inherit" } = metadata;
  const [fullWidth, setFullWidth] = useState(false);
  const onError = () => {
    imgRef.current.src = "/not-found.svg";
  };

  const onLoad = () => {
    const imgWidth = imgRef?.current?.naturalWidth;
    if (imgWidth && imgWidth >= 400) {
      setFullWidth(true);
    }
  };

  return (
    <div className={clx("nft-item", open && "open")}>
      <div
        style={{ backgroundColor: background_color }}
        className={clx("item", fullWidth && "full-width")}
      >
        <img
          onClick={() => setOpen(true)}
          ref={imgRef}
          src={image ? image : "/not-found.svg"}
          onError={onError}
          onLoad={onLoad}
          alt=""
        />
      </div>
    </div>
  );
};
