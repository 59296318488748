import { Routes, Route } from "react-router-dom";

import { ConsentFrame, NotFound } from "./containers";

const FormLayout = ({ children }) => {
  return <div className="form-wrapper">{children}</div>;
};

function App() {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <FormLayout>
            <ConsentFrame />
          </FormLayout>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
