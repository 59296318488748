import { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { postMessage, parseConsentData } from "../../utils";
import { cachedConsentData } from "../../api";
import { Loader } from "../../component";

/**
 * @author nadeem@passbird.co
 * Higher order component to wrap the component with consent data logic
 * idea is to render a common loading logic which is not possible with hooks
 * with hooks we would have to write separate loading flow for each component
 * @param {React component} Component react component to be wrapped
 * @returns Wrapped React Component
 */

export const withConsentData = (Component) => {
  const ComponentWithConsentData = (props) => {
    const [msg, setMsg] = useState({ type: null, content: null });
    const [consentData, setConsentData] = useState(null);
    const [loading, setLoading] = useState(true);

    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const consentToken = params.get("consentToken");

    const fetchConsentDetails = useCallback(async () => {
      try {
        setLoading(true);
        const result = await cachedConsentData(consentToken);
        const consentDetails = parseConsentData(result);
        setConsentData({ ...consentDetails });
      } catch (err) {
        const errData = err?.response?.data || err;
        const content = errData?.status || "Something went wrong";
        postMessage(
          "Error",
          errData,
          setMsg({ type: "err", content: content })
        );
      } finally {
        setLoading(false);
      }
    }, [consentToken]);

    useEffect(() => {
      if (consentToken === null || consentToken === "null") {
        // if consentToken is null save the api req and set error msg
        postMessage(
          "Errror",
          { status: "NO_TOKEN_FOUND" },
          setMsg({ content: "No Token Found", type: "err" })
        );
        setLoading(false);
        return;
      }
      fetchConsentDetails();
    }, [fetchConsentDetails, consentToken]);

    if (loading)
      return (
        <div className="empty-state in-frame">
          <Loader />
        </div>
      );

    return (
      <Component
        msg={msg}
        consentToken={consentToken}
        setMsg={setMsg}
        loading={loading}
        setConsentData={setConsentData}
        setLoading={setLoading}
        {...consentData}
      />
    );
  };
  return ComponentWithConsentData;
};
