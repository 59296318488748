import clx from "classnames";
import { useState, useRef, useEffect } from "react";
// keyCode constants
const BACKSPACE = 8;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const DELETE = 46;
const SPACEBAR = 32;

export const OTPField = ({ onChangeHandler, hasError = false }) => {
  const fieldRefs = useRef({});
  // can be dynamic prop based on the length of the OTP
  const [values, setValues] = useState({
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
  });

  /**
   * Call prop (Container) change handler
   */
  useEffect(() => {
    const entries = Object.values(values).join("");
    if (entries) onChangeHandler(entries);
  }, [onChangeHandler, values]);

  /**
   *
   * @param {String} value - value of the input
   * @returns Boolean - true if the value is a number
   */
  const isValidInput = (value) => {
    return !isNaN(parseInt(value, 10));
  };

  /**
   * @param {String} targetId - target id of element
   * @param {String} value - value of target input
   * @description - updates the state of individual input
   */
  const setValueAtTarget = (targetId, value) => {
    setValues((prevState) => ({ ...prevState, [targetId]: value }));
  };

  /**
   * @param {String} targetId - target id of element
   * @description - update focus to corresponding input target
   */
  const updateFocus = (targetId) => {
    const fieldRef = fieldRefs.current[targetId];
    if (fieldRef) fieldRef.focus();
  };

  /**
   * Handles change event on input
   * if the value is a number, update the state of the input
   * and change focus to next input
   */
  const onChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    if (isValidInput(value)) {
      setValueAtTarget(id, value);
      updateFocus(parseInt(id, 10) + 1);
    }
  };

  /**
   * Handle keydown event on input
   * checks for special keys and handles accordingly
   * Handles - Backspace, Left Arrow, Right Arrow, Delete, Spacebar
   */
  const onKeyDown = (e) => {
    const { id } = e.target;
    if (e.keyCode === BACKSPACE || e.key === "Backspace") {
      e.preventDefault();
      const prevTarget = parseInt(id) - 1;
      setValueAtTarget(id, "");
      updateFocus(prevTarget);
    } else if (e.keyCode === DELETE || e.key === "Delete") {
      e.preventDefault();
      setValueAtTarget(id, "");
    } else if (e.keyCode === LEFT_ARROW || e.key === "ArrowLeft") {
      e.preventDefault();
      const prevTarget = parseInt(id) - 1;
      updateFocus(prevTarget);
    } else if (e.keyCode === RIGHT_ARROW || e.key === "ArrowRight") {
      e.preventDefault();
      const nextTarget = parseInt(id) + 1;
      updateFocus(nextTarget);
    } else if (
      e.keyCode === SPACEBAR ||
      e.key === " " ||
      e.key === "Spacebar" ||
      e.key === "Space"
    ) {
      e.preventDefault();
    }
  };

  return (
    <div className="otp-field-container">
      <input
        id="1"
        ref={(elm) => (fieldRefs.current[1] = elm)}
        className={clx("otp-field", hasError && "error")}
        type="text"
        maxLength={1}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={values[1]}
        data-testid="field-1"
      />
      <input
        id="2"
        ref={(elm) => (fieldRefs.current[2] = elm)}
        className={clx("otp-field", hasError && "error")}
        type="text"
        maxLength={1}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={values[2]}
        data-testid="field-2"
      />
      <input
        id="3"
        ref={(elm) => (fieldRefs.current[3] = elm)}
        className={clx("otp-field", hasError && "error")}
        type="text"
        maxLength={1}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={values[3]}
        data-testid="field-3"
      />
      <span>-</span>
      <input
        id="4"
        ref={(elm) => (fieldRefs.current[4] = elm)}
        className={clx("otp-field", hasError && "error")}
        type="text"
        maxLength={1}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={values[4]}
        data-testid="field-4"
      />
      <input
        id="5"
        ref={(elm) => (fieldRefs.current[5] = elm)}
        className={clx("otp-field", hasError && "error")}
        type="text"
        maxLength={1}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={values[5]}
        data-testid="field-5"
      />
      <input
        id="6"
        ref={(elm) => (fieldRefs.current[6] = elm)}
        className={clx("otp-field", hasError && "error")}
        type="text"
        maxLength={1}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={values[6]}
        data-testid="field-6"
      />
    </div>
  );
};
