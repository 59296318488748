import { CheckSVG } from "./CheckSVG";
import { CrossSVG } from "./CrossSVG";
export const CompletedState = ({ msg, isSuccess = true }) => {
  return (
    <div className="completed-state">
      <div className="completed-state-icon">
        {isSuccess ? <CheckSVG /> : <CrossSVG />}
      </div>
      <div className="completed-state-copy">{msg}</div>
    </div>
  );
};
