import { POOL_ID, AWS_REGION, COGNITO_CLIENT_ID } from "./constants";
const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});
export const awsConfig = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: AWS_REGION,

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: AWS_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: COGNITO_CLIENT_ID,
    authenticationFlowType: "CUSTOM_AUTH",
    clientMetadata: { consentToken: params.consentToken },
  },
};
