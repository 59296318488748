import { Spinner } from "reactstrap";

/**
 * @author: nadeem@passbird.co
 * @param {string} loaderText text for loading state
 * @param {Object} style inline style for the wrapper div
 * @returns React Loading Component
 */
export const Loader = ({ loaderText = "Loading", style }) => (
  <div style={style} className={"loader"}>
    {loaderText}
    <span className={"dotOne"}>.</span>
    <span className={"dotTwo"}>.</span>
    <span className={"dotThree"}>.</span>
  </div>
);

export const LoaderWithBackdrop = () => {
  return (
    <div className="loader-wrapper">
      <div className="loader-backdrop">
        <Spinner color="dark" />
      </div>
    </div>
  );
};
